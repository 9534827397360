import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const ImageCropper = ({ imgSrc, setImgSrc, setImageCropped }) => {
  const [image, setImage] = useState(imgSrc);
  const cropperRef = useRef(null);
  const [showConfirmButton, setShowConfirmButton] = useState(false); // Control visibility of confirm button

  const cropImage = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      if (croppedCanvas) {
        const url = croppedCanvas.toDataURL(); // Convert canvas to Data URL
        setImgSrc(url); // Pass the cropped image back to the parent
        setImageCropped(true); // Notify parent that the image is cropped
        setShowConfirmButton(false); // Hide the button after crop is confirmed
      }
    }
  };

  const handleCropEnd = () => {
    // Show the confirm crop button when cropping ends
    setShowConfirmButton(true);
  };

  return (
    <div>
      {image && (
        <>
          <Cropper
            style={{ height: 400, width: '100%' }}
            initialAspectRatio={1}
            aspectRatio={NaN} // Allows for a free aspect ratio
            src={image}
            ref={cropperRef}
            viewMode={1}
            guides={false}
            cropend={handleCropEnd} // Trigger when the user stops interacting
          />
          {/* Show Confirm Crop button after crop ends */}
          {showConfirmButton && (
            <div className='confirm-crop-btn'>
              <button onClick={cropImage} style={{ padding: '10px 20px', cursor: 'pointer' }}>
                Confirm Crop
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ImageCropper;
