import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Loader from './loader';
import ImageCropper from '../crop';
import Image from 'next/image';

function VisualSearch2(props) {
const [openFilters, setOpenFilters] = useState({});
const [loading, setloading] = useState(false);
const [totalProds, settotalProds] = useState(true);
const [facets, setfacets] = useState([]);
const [searchMetaData, setsearchMetaData] = useState({});
const [Products, setProducts] = useState([]);
const [perPage, setperPage] = useState(0);
const [AppliedFacet, setAppliedFacet] = useState({});
const [ImageType, seImageType] = useState("");
const [ImageData, seImageData] = useState("");
const [imageCrop, setImageCrop] = useState(false)
const[imgSrc, setImgSrc]= useState(props.base64)
const[loadmore,setloadmore] = useState([])
const [SortBy, setSortBy] = useState("*");
const[imgCropped, setImageCropped] = useState(false)
const[openMob, setOpenMob] = useState(false)
console.log("ooooo", imgCropped)
function convertPricePerSqmToSqft(pricePerSqm) {
const conversionFactor = 10.7639;
return (pricePerSqm / conversionFactor).toFixed(0);
}
let loadMore = async () => {
const result = {};
let obj = {
...AppliedFacet,
};
for (const [key, value] of Object.entries(obj)) {
if (value != null && value !== '') {
result[key] = value;
}
}
setAppliedFacet({ ...result });
const urlObj = new URL(`https://search.unbxd.io//v2.0/sites/ss-unbxd-aapac-prod-orientbell53741713874410/images`);
Object.entries(result).forEach(([k, v]) => {
const encodedFilter = `${k}:"${v}"`; 
urlObj.searchParams.append('filter', encodedFilter);
});
urlObj.searchParams.append('rows', perPage + 9);
// if(SortBy != "*"){
// urlObj.searchParams.append('sort', `${SortBy}`);
// }
setperPage(perPage + 9)
try {
getVisualResult(urlObj.toString(), ImageType, ImageData)
} catch (error) {
console.log(error)
swal("Something went wrong please try again later")
}
console.log(urlObj.toString(), ImageType, ImageData);
};
let getResultwithFacet = async (key, value) => {
const result = {};
let obj = {
...AppliedFacet,
[key]: value
};
for (const [key, value] of Object.entries(obj)) {
if (value != null && value !== '') {
result[key] = value;
}
}
setAppliedFacet({ ...result });
const urlObj = new URL(`https://search.unbxd.io//v2.0/sites/ss-unbxd-aapac-prod-orientbell53741713874410/images`);
Object.entries(result).forEach(([k, v]) => {
const encodedFilter = `${k}:"${v}"`; 
urlObj.searchParams.append('filter', encodedFilter);
});
urlObj.searchParams.append('rows', 9);
setperPage(9)
try {
getVisualResult(urlObj.toString(), ImageType, ImageData)
} catch (error) {
console.log(error)
swal("Something went wrong please try again later")
}
console.log(urlObj.toString(), ImageType, ImageData);
};
let applysort = async (s) => {
const result = {};
let obj = {
...AppliedFacet,
};
for (const [key, value] of Object.entries(obj)) {
if (value != null && value !== '') {
result[key] = value;
}
}
setAppliedFacet({ ...result });
const urlObj = new URL(`https://search.unbxd.io//v2.0/sites/ss-unbxd-aapac-prod-orientbell53741713874410/images`);
Object.entries(result).forEach(([k, v]) => {
const encodedFilter = `${k}:"${v}"`; 
urlObj.searchParams.append('filter', encodedFilter);
});
urlObj.searchParams.append('rows', perPage);
setSortBy(s)
if(s != "*"){
urlObj.searchParams.append('sort', `${s}`);
}
try {
getVisualResult(urlObj.toString(), ImageType, ImageData)
} catch (error) {
console.log(error)
swal("Something went wrong please try again later")
}
console.log(urlObj.toString(), ImageType, ImageData);
};
const getVisualResult = async (APIUrl, typee, dataa) => {
setloading(true); // Set loading to true before the request
const options = {
method: 'POST',
url: APIUrl,
headers: {
'Content-Type': typee, // Ensure this is just the MIME type (image/jpeg, image/png, etc.)
Authorization: '5a4b9251b67027ff94d260e4ac6ac09e', // Replace with your actual API key
},
data: dataa, // Base64 data without the "data:image/jpeg;base64," prefix
};
axios(options)
.then((response) => {
setloading(false); // Set loading to false after success
// handle response data
settotalProds(response.data.response.numberOfProducts)
setProducts(response.data.response.products)
setperPage(response.data.response.products.length)
setsearchMetaData(response.data.searchMetaData)
setfacets(response.data.facets.text.list)
console.log(response.data);
})
.catch((error) => {
setloading(false); // Set loading to false after error
console.log('Error:', error);
});
};
useEffect(() => {
if (props.base64 && !imgCropped) {
console.log("IMG not cropped yet", props.base64)
// Split base64 string into type and actual base64 data
let splitdata = props.base64.split(',');
// Ensure correct MIME type is extracted (e.g., "image/jpeg")
let mimeType = splitdata[0].match(/data:(.*?);base64/)[1]; // Extract "image/jpeg" or similar
seImageData(splitdata[1])
seImageType(splitdata[0].split(':')[1])
// Pass the extracted MIME type and base64 data to the API call
getVisualResult(
'https://search.unbxd.io/v2.0/sites/ss-unbxd-aapac-prod-orientbell53741713874410/images',
splitdata[0].split(':')[1],
splitdata[1] // Base64 data without prefix
);
}
else if(imgCropped){
// Split base64 string into type and actual base64 data
console.log("IMG ", imgSrc)
let splitdata = imgSrc.split(',');
// Ensure correct MIME type is extracted (e.g., "image/jpeg")
// let mimeType = splitdata[0].match(/data:(.*?);base64/)[1]; // Extract "image/jpeg" or similar
seImageData(splitdata[1])
seImageType(splitdata[0].split(':')[1])
// Pass the extracted MIME type and base64 data to the API call

getVisualResult(
'https://search.unbxd.io/v2.0/sites/ss-unbxd-aapac-prod-orientbell53741713874410/images',
splitdata[0].split(':')[1],
splitdata[1] // Base64 data without prefix
);
}
}, [props.base64, imgCropped,imgSrc]); // Trigger effect when props.base64 changes

// Toggle the specific filter based on index
const toggleFilter = (index) => {
setOpenFilters((prevState) => ({
...prevState,
[index]: !prevState[index], // Toggle the current filter's open state
}));
}; return (
<div class='new-visual-search-modal'>
{/* {loading 
?
 <Loader/> */}
 {/* : */}
<Modal show={props.base64} onHide={()=>props.setbase64content(false)} className='visual-modal'>
<Modal.Dialog >
<Modal.Header closeButton>
</Modal.Header>

<Modal.Body>
  {
    loading?
    <div className='loading-content'>
        <Image src ='/loader.gif' alt ="loader" width='200' height='200'></Image>
    <h6>Hold tight! Your awesome results are on their way...</h6>
  </div>
    :

<div className={openMob ? 'search-similar-product-sectio expanded-sec' : 'search-similar-product-sectio '}>
<div className='search-similar-product-left'>
<div className='search-similarproduct-head'>
<h6>Shopping Matches</h6>
</div>
<div className='similarproduct-left-row-data'>
<div className='similarproduct-left-scroll-bx'>



<div className= { !imageCrop ? 'similarproduct-img crop-img-filter' :  'similarproduct-img'}>
  { !imageCrop 
    ? <img src={imgSrc} alt="similar product" /> 
    : <ImageCropper imgSrc={props.base64} setImgSrc={setImgSrc} setImageCropped={setImageCropped} />
  }

  { !imageCrop && (
  <div className='crop-icon-section'>
    <img src='/icons8-crop-50.png' alt="crop icon" onClick={() => setImageCrop(true)} />
  </div>
)}
</div>

<div className='similarproduct-filter'>
<div className='similarproduct-filter-mobile-heading'>
  <p>All Filters</p>
  <button type="button" class="btn-close" aria-label="Close" onClick={()=>setOpenMob(false)}></button>
</div>
<div className='similarproduct-filter-row'>
{Array.isArray(facets) && facets.map((face, index) => (
<div className='similarproduct-filter-col' key={index}>
<div
className={openFilters[index] ? 'flt-type-heading open' : 'flt-type-heading'}
onClick={() => toggleFilter(index)}
>
{face.displayName}
</div>
{openFilters[index] && (
<div className='flt-type-listing'>
<ul class="ais-RefinementList-list">
{(loadmore.includes(face.displayName) && face.values.filter((f)=>typeof f != "number").length > 3) ? face.values.filter((f)=>typeof f != "number").map((cc)=>{
return (
<li class="ais-RefinementList-item"><label class="ais-RefinementList-label"><input class="ais-RefinementList-checkbox" type="checkbox" onChange={(e)=>{
if(e.target.checked){
getResultwithFacet(face.facetName, cc ) 
} else {
getResultwithFacet(face.facetName, "" ) 
}
}} checked={AppliedFacet[face.facetName] == cc}/><span class="ais-RefinementList-labelText">{cc}</span></label></li>
)
})
: <>{face.values.filter((f)=>typeof f != "number").slice(0, 3).map((cc)=>{
return (
<li class="ais-RefinementList-item"><label class="ais-RefinementList-label"><input class="ais-RefinementList-checkbox" type="checkbox" onChange={(e)=>{
if(e.target.checked){
getResultwithFacet(face.facetName, cc ) 
} else {
getResultwithFacet(face.facetName, "" ) 
}
}} checked={AppliedFacet[face.facetName] == cc}/><span class="ais-RefinementList-labelText">{cc}</span></label></li>
)
})
}
{face.values.filter((f)=>typeof f != "number").length > 3 &&
<p className='load-more-btn' onClick={()=>{
setloadmore([...loadmore, face.displayName])
}} style={{color:"blue", marginBottom:"0px !important"}}>load more</p>}
</>}
</ul>
<ul>
</ul>
</div>
)}
</div>
))}
</div>
</div>
</div>
</div>
</div>
<div className='search-similar-product-right'>
<div className='search-similarproduct-head'>
<div className='similarproduct-select'>
<select class="select-bx" value={SortBy} onChange={(e)=>{
applysort(e.target.value)
}}>
<option value="*">Relevance</option>
<option value="price desc">Price (high to low)</option>
<option value="price asc">Price (low to high)</option>
</select>
</div>
<div className='similarproduct-item'>
<p>{totalProds} items</p>
</div>
<div className='similarproduct-mobile-filter' onClick={()=>setOpenMob(true)}>
    <p><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="24" height="24" fill="white"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00039 4.5C8.77359 4.5 9.40039 5.1268 9.40039 5.9C9.40039 6.6732 8.77359 7.3 8.00039 7.3C7.22719 7.3 6.60039 6.6732 6.60039 5.9C6.60039 5.1268 7.22719 4.5 8.00039 4.5ZM10.3483 5.40039C10.1184 4.3147 9.15455 3.5 8.00039 3.5C6.81119 3.5 5.82401 4.36491 5.63357 5.5H4C3.72386 5.5 3.5 5.72386 3.5 6C3.5 6.27614 3.72386 6.5 4 6.5H5.676C5.94243 7.53514 6.88209 8.3 8.00039 8.3C9.15427 8.3 10.118 7.48569 10.3482 6.40039H20.4C20.6761 6.40039 20.9 6.17653 20.9 5.90039C20.9 5.62425 20.6761 5.40039 20.4 5.40039H10.3483ZM8.00039 16.5C8.77359 16.5 9.40039 17.1268 9.40039 17.9C9.40039 18.6732 8.77359 19.3 8.00039 19.3C7.22719 19.3 6.60039 18.6732 6.60039 17.9C6.60039 17.1268 7.22719 16.5 8.00039 16.5ZM8.00039 15.5C9.15455 15.5 10.1184 16.3147 10.3483 17.4004H20.4C20.6761 17.4004 20.9 17.6242 20.9 17.9004C20.9 18.1765 20.6761 18.4004 20.4 18.4004H10.3482C10.118 19.4857 9.15427 20.3 8.00039 20.3C6.88209 20.3 5.94243 19.5351 5.676 18.5H4C3.72386 18.5 3.5 18.2761 3.5 18C3.5 17.7239 3.72386 17.5 4 17.5H5.63357C5.824 16.3649 6.81119 15.5 8.00039 15.5ZM14.5996 11.4996C14.5996 10.7264 15.2264 10.0996 15.9996 10.0996C16.7728 10.0996 17.3996 10.7264 17.3996 11.4996C17.3996 12.2728 16.7728 12.8996 15.9996 12.8996C15.2264 12.8996 14.5996 12.2728 14.5996 11.4996ZM15.9996 9.09961C14.8454 9.09961 13.8816 9.91431 13.6517 11H4C3.72386 11 3.5 11.2239 3.5 11.5C3.5 11.7761 3.72386 12 4 12H13.6518C13.882 13.0853 14.8457 13.8996 15.9996 13.8996C17.1573 13.8996 18.1236 13.0799 18.3496 11.9892C18.3792 11.9963 18.4098 12 18.4412 12H20.5588C20.8025 12 21 11.7761 21 11.5C21 11.2239 20.8025 11 20.5588 11H18.4412C18.4098 11 18.3793 11.0037 18.3498 11.0107C18.124 9.91969 17.1576 9.09961 15.9996 9.09961Z" fill="#191919"/> </svg> Filter</p>
</div>
</div>
<div className='searchsimilar-prodect-section'>
<div className='searchsimilar-prodect-row'>
{Products.length ? Products.map((prod)=>{
return (
<div className='searchsimilar-prodect-col'>
<a href={prod.productUrl.replace("https://server.orientbell.com/", "/")}>
<div className='searchsimilar-prodect-img'>
<img src={prod.imageUrl.length ? prod.imageUrl[0] : ""}></img>
</div>
<div className='searchsimilar-prodect-text'>
<h6>{prod.title}</h6>
<p>MRP ₹ {convertPricePerSqmToSqft(prod.price)} /- Sq.ft</p>
</div>
</a>
</div>
)
}) : <p>Mo Results Found</p>}
</div>
{perPage != settotalProds && 
<div className='searchsimilar-loar-more-btn'>
<a href="#" onClick={(e)=>{
e.preventDefault()
loadMore()
}}>Load More</a>
</div>}
</div>
</div>
</div>
  }
</Modal.Body>

</Modal.Dialog>
</Modal>

{/* } */}
</div>
)
}

export default VisualSearch2
